import http from "../http";

// Export all user Service
export const userService = {
  userListService,
  createUserService,
  getUserDetailsService,
  updateUserService,
  removeUserService,
  getClientPortalAccessToken
};

// Get All User Service
function userListService(payload : {perPage: string, page: string}) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/user/?perPage=${payload.perPage}&page=${payload.page}`, );
}

// Create User Service
function createUserService(payload : { firstName: string, lastName: string, email: string, password: string, userRole: string}) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/user/`, {
    ...payload
  });
}

// get user details service
function getUserDetailsService(userId : any) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/user/${userId}`);
}

// Update User Service
function updateUserService(payload : any) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/user/${payload?.userId}`, {
    ...payload?.data
  });
}

// Remove User Service
function removeUserService(payload : { userId: string }) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/user/${payload?.userId}/status`);
}

// get client portal access token
function getClientPortalAccessToken(id: string) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/user/get-token/${id}`)
}

 

