import { createAsyncThunk } from '@reduxjs/toolkit';
import { userService } from '../../../services/users/user.service';
import { showHideLoader } from '../../common/common.slice';

// Async Thunk Actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

// Create User Actions
const createUsersAction = createAsyncThunk(
  'userSlice/userAction', (payload: { firstName: string, lastName: string, email: string, password: string, userRole: string}, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return userService.createUserService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// Get user details action
const getUserDetailsAction = createAsyncThunk(
  'userSlice/getUserDetailsAction', (userId: string, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return userService.getUserDetailsService(userId)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

const updateUsersAction = createAsyncThunk(
  'userSlice/updateUsersAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return userService.updateUserService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

export const userActions = {
  createUsersAction,
  getUserDetailsAction,
  updateUsersAction
}

