import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store/store';
import { projectsActions } from './projects.actions';

export interface userState {
  value: any;
  status: 'idle' | 'loading' | 'failed';
}


const projectsSlice = createSlice({
  name: "projects",
  initialState: {
    projectsListData: [],
    projectListFiltersData: <any>null,
    projectsListError: "",
    projectsAutocompleteData: [],
    error: "",
    loadingAutocomplete: false,
    autoCompleteError: "",
    usersListData: null,
    projectCreated: false,
    activePagination: {},
    
    projectDetails: <any>null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
       // -------- cases of projects autocomplete api data -------
       .addCase(projectsActions.projectsAutocompleteAction.pending, (state, action) => {
        state.error = "";
        state.projectsAutocompleteData = [];
        state.loadingAutocomplete = true;
      })
      .addCase(projectsActions.projectsAutocompleteAction.fulfilled, (state, action) => {
        state.loadingAutocomplete = false;
        if (action?.payload?.data?.status===200) {
          state.projectsAutocompleteData= action?.payload?.data?.data;
          state.error = "";
        } else {
          state.loadingAutocomplete = false;
          state.error = "No records found.";
        }
      })
      .addCase(projectsActions.projectsAutocompleteAction.rejected, (state, action:any) => {
        state.loadingAutocomplete = false;
        state.projectsAutocompleteData = [];
        state.error = action?.payload?.data?.message || "";
      })
      // -------- cases of projects users list api data -------
      .addCase(projectsActions.getUsersList.pending, (state, action) => {
        state.error = "";
        state.usersListData = null;
      })
      .addCase(projectsActions.getUsersList.fulfilled, (state, action) => {
        if (action?.payload?.data?.status) {
          // needs to be updated to accomodate all types of users
          const usersLists = action?.payload?.data?.data; 
          const userList1:any = {
            project_manager: usersLists?.project_manager ?? [],
            designer: usersLists?.designer ?? [],
            administrator: usersLists?.administrator ?? [],
            superadmin: usersLists?.superadmin ?? [],
          };
          
          state.usersListData= userList1
          state.error = "";
        } else {
          state.error = "Unable to fetch project users List.";
        }
      })
      .addCase(projectsActions.getUsersList.rejected, (state, action:any) => {
        state.usersListData = null;
        state.error = action?.payload?.data?.message || "";
      })
      // -------- cases of create project api data -------
      .addCase(projectsActions.createProjectAction.pending, (state, action) => {
        state.error = "";
        state.projectCreated = false;
      })
      .addCase(projectsActions.createProjectAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.projectCreated= action?.payload?.data?.message;
          state.error = "";
        } else {
          state.error = "Unable to create project.";
        }
      })
      .addCase(projectsActions.createProjectAction.rejected, (state, action: any) => {
        state.projectCreated = false;
        state.error = action?.payload?.data?.message ?? "Unable to create project.";
      })
      // -------- cases of update project api data -------
      .addCase(projectsActions.updateProjectAction.pending, (state, action) => {
        state.error = "";
      })
      .addCase(projectsActions.updateProjectAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.error = "";
        } else {
          state.error = "Unable to update project.";
        }
      })
      .addCase(projectsActions.updateProjectAction.rejected, (state, action: any) => {
        state.error = action?.payload?.data?.message ?? "Unable to update project.";
      })
      // -------- cases of projects list api data -------
      .addCase(projectsActions.getProjectsAction.pending, (state, action) => {
        state.projectsListError = "";
        state.projectsListData = [];
        state.activePagination = {};
        state.projectListFiltersData = action?.meta?.arg;
      })
      .addCase(projectsActions.getProjectsAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.projectsListData= action?.payload?.data?.data;
          state.activePagination = action?.payload?.data?.pagination;
          state.projectsListError = "";
        } else {
          state.error = "Unable to fetch projects list.";
          state.activePagination = {}
        }
      })
      .addCase(projectsActions.getProjectsAction.rejected, (state, action:any) => {
        state.projectsListData = [];
        state.activePagination = {}
        state.projectsListError = action?.payload?.data?.message || "";
      })

      // -------- cases of get client details api data -------
      .addCase(projectsActions.getClientDetailsAction.pending, (state) =>{
        state.projectDetails = { ...state.projectDetails }; // Preserve existing data
      })
      .addCase(projectsActions.getClientDetailsAction.fulfilled,(state, action) =>{
        state.projectDetails ={
          ...state.projectDetails,
          clients : action?.payload?.data?.data
        }
      })
      .addCase(projectsActions.getClientDetailsAction.rejected, (state) =>{
        state.projectDetails = { ...state.projectDetails }; // Preserve existing data
      })
   
      // -------- cases of get project camera api data -------
      .addCase(projectsActions.getProjectCameraAction.pending, (state) => { 
        state.projectDetails = {...state.projectDetails}
      })
      .addCase(projectsActions.getProjectCameraAction.fulfilled, (state, action) => {
        state.projectDetails = {
          ...state.projectDetails,
          cameras: action?.payload?.data?.data
        };
      })
      .addCase(projectsActions.getProjectCameraAction.rejected, (state,) => {
        state.projectDetails = {...state.projectDetails}
      })

      // -------- cases of save project camera api data -------
      // .addCase(projectsActions.saveProjectCameraAction.pending, (state, action) => {
      //   state.projectDetails = null;
      // })
      // .addCase(projectsActions.saveProjectCameraAction.fulfilled, (state, action) => {
      //   state.projectDetails = {
      //     cameras: action?.payload?.data?.data
      //   };
      // })
      // .addCase(projectsActions.saveProjectCameraAction.rejected, (state, action:any) => {
      //   state.projectDetails = null;
      // })
  },
  
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const token = (state: RootState) => state.login.token;


export default projectsSlice.reducer;
