import http from "../http";

// Export all Project Service
export const projectsService = {
  projectsListService,
  activateDealService,
  removeClientService,
  resendInviteService,
  projectDetailsService,
  projectDetailsRoomsUpdateService,
  projectDetailsNotesListingService,
  projectDetailsCreateNoteService,
  projectDetailsUpdateNoteService,
  projectDetailsDeleteNoteService,
  getRootFoldersService,
  getChildFoldersService,
  getUploadedDocListService,
  uploadDocumentsMediaService,
  updateLabelService,
  deleteUploadedFileService,
  getDocumentMediaFilesService,
  getDocumentMediaFoldersService,
  uploadDocumentMediaFilesService,
  updateDocumentMediaLabelService,
  deleteDocumentMediaFileService,
  downloadFolderService,
  getProjectProposalsService
};

// Sales Projects list Service
function projectsListService(payload: any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/sales/project/deals-list/?page=${payload?.page}&perPage=${payload?.perPage}`, {
    filters: payload?.filters,
    sort: payload?.sort
  });
}

// activate deal service
function activateDealService(payload:any) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/sales/project/${payload?.id}/customer`)
}

// remove Client Services
function removeClientService(payload : {projectIds : any}){
  return http.delete(`${process.env.REACT_APP_BASE_URL}/projects/${payload?.projectIds}`);
}

// resend invite service
function resendInviteService(payload:any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/sales/project/${payload?.dealId}/resend-invite`)
}

// Sales Project details service
function projectDetailsService(projectId: string) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/sales/project/${projectId}`);
}

// get uploaded document list Service
function getUploadedDocListService(payload: {dealId: string, documentType: string}) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/sales/project/get-document-media?dealId=${payload.dealId}&documentType=${payload.documentType}`);
}

// Sales Project save room service
function projectDetailsRoomsUpdateService(payload: any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/sales/project/save-item`, {
    ...payload
  })
}

// Sales Project Details get notes listing
function projectDetailsNotesListingService(payload: any){
  return http.post(`${process.env.REACT_APP_BASE_URL}/sales/project/get-notes`, payload)
}

// Sales Project Details create note
function projectDetailsCreateNoteService(payload: any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/sales/project/create-notes`, payload)
}

// Upload Documents/Media
function uploadDocumentsMediaService({payload,dealId}: any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/sales/project/${dealId}/documents-media/upload`, 
    payload,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  )
}

// Update labels Service
function updateLabelService({payload, id}:any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/sales/project/update-document-media/${id}`, 
    payload,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
}

// Sales Project Details update note
function projectDetailsUpdateNoteService(payload: any) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/sales/project/${payload.noteId}`, {
    note: payload.note
  })
}

// Sales Project Details delete note
function projectDetailsDeleteNoteService(payload: any) {
  return http.delete(`${process.env.REACT_APP_BASE_URL}/sales/project/${payload.noteId}`)
}

// ---------------------------------------------------------------------------------------

// get root folders service
function getRootFoldersService(payload:any) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/projects/${payload?.id}/folder?orderBy=${payload?.orderBy}&order=${payload?.order}`);
}

// get child folders service
function getChildFoldersService(payload:any) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/projects/${payload?.id}/folder/${payload?.folderId}?orderBy=${payload?.orderBy}&order=${payload?.order}`);
}

// ---------------------------------------------------------------------------------------

// Delete uploaded file Service
function deleteUploadedFileService(payload: {id: string, documentType: string}) {
  return http.delete(`${process.env.REACT_APP_BASE_URL}/sales/project/document-media/${payload.id}?documentType=${payload.documentType}`);
}

// get document media recent and all file Service
function getDocumentMediaFilesService(payload:any) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/projects/${payload?.id}/recent?documentType=${payload?.documentType}&folderName=${payload?.folder}`);
}

// get document/ media folders Service
function getDocumentMediaFoldersService(payload:any) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/projects/${payload?.id}/folder?documentType=${payload?.documentType}`);
}

// upload document/ media files Service
function uploadDocumentMediaFilesService(payload:any, dealId:any, loader:any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/projects/${dealId}/document-media/upload`,
    payload,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
}

// upload document/ media files Service
function updateDocumentMediaLabelService(payload:any, projectId:any) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/projects/${projectId}/update-document-media`,
    payload,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
}

// delete document/ media file Service
function deleteDocumentMediaFileService(projectId:any, documentType: any) {
  return http.delete(`${process.env.REACT_APP_BASE_URL}/projects/${projectId}/document-media?documentType=${documentType}`);
}

// Download folder Service
function downloadFolderService(formData: any, projectId:any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/projects/${projectId}/generate-folder-zip`,
  formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    });
}

// get project proposals Service
function getProjectProposalsService(projectId:any) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/projects/${projectId}/list-proposals`);
}