import { createAsyncThunk } from '@reduxjs/toolkit';
import { userService } from '../../../services/users/user.service';
import { showHideLoader } from '../../common/common.slice';

// Async Thunk Actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

// Get all Users Action
const getUsersAction = createAsyncThunk(
  'userSlice/userAction', (payload: { perPage: string, page: string}, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return userService.userListService(payload)
      .then((response) => {
        response.data.data.map((ele: any) => {
          return ele.name = ele.firstName + ' ' + ele.lastName;
        })
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

const removeUsersAction = createAsyncThunk(
  'userSlice/removeUsersAction', (payload: { userId: string }, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return userService.removeUserService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

export const userActions = {
  getUsersAction,
  removeUsersAction
}

