import http from "../http";

// Export all Project Service
export const projectsService = {
  projectsAutocompleteService,
  getUsersListService,
  createProjectService,
  updateProjectService,
  projectListService,
  getProjectCameraService,
  saveProjectCameraService,
  getClientDetailsService,
  saveClientDetailsService
};

// Project Autocomplete Service
function projectsAutocompleteService(query: string) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/projects/autcomplete?term=${query}`, );
}

// Project User list Service
function getUsersListService() {
  return http.get(`${process.env.REACT_APP_BASE_URL}/projects/user-list`, {
  });
}

// Active Projects list Service
function projectListService(payload: {perPage: string, page: string, filters: any}) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/projects?perPage=${payload.perPage}&page=${payload.page}&term=${payload?.filters?.term}`, {
  });
}

// Create Projects Service
function createProjectService(payload: any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/projects`, {
    ...payload
  });
}

// Update Project Service
function updateProjectService(payload: any) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/projects/${payload?.projectId}`, payload?.data);
}

// get project camera list
function getProjectCameraService(payload: any) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/projects/${payload?.projectId}/cameras`);
}


// save project camera list
function saveProjectCameraService(payload: any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/projects/${payload?.projectId}/cameras`, payload?.data);
}

// get project clients Details
function getClientDetailsService(payload: any){
  return http.get(`${process.env.REACT_APP_BASE_URL}/customer/${payload.data.id}`);
}

// save project client Details
function saveClientDetailsService(payload: any) {
  return http.put(
    `${process.env.REACT_APP_BASE_URL}/customer/${payload?.clientId}`,
    payload?.data, // The data to be sent in the request body
  );
}

 

