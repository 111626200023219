import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { salesProjectsActions } from './projects.actions';

export interface userState {
  value: any;
  status: 'idle' | 'loading' | 'failed';
}


const salesProjectsSlice = createSlice({
  name: "salesProjects",
  initialState: {
    projectsListData: [],
    projectListFiltersData: null,
    activePagination: {},
    projectsListError: "",
    projectDetails: null,
    projectDetailsError: "",
    projectDetailsNotes: [],
    projectDetailsNotesFilters: {},
    projectDetailsRoomUpdate: null,
    projectDetailsCreateNote: null,
    projectDetailsUpdateNote: null,
    projectDetailsDeleteNote: null,
    uploadedDocMediaData: [],

    projectFolders: null,

    docMediaFolders: [],
    uploadedFileData: [],
    uploadDocError: "",
    updateLabel: [],
    deleteFile: [],
    uploadedDocMediaFiles: [],
    docMediaFiles: [],
    updateDocMediaLabels: [],
    deleteDocMedia: null,
    downloadFolder: null,
    projectProposals: [],
    error: "",
  },
  reducers: {
    cleanProjectDetails: (state) => {
      state.projectDetails = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // -------- cases of projects list api data -------
      .addCase(salesProjectsActions.getProjectsListAction.pending, (state, action:any) => {
        state.projectsListError = "";
        state.projectsListData = [];
        state.activePagination = {};
        state.projectListFiltersData = action?.meta?.arg;
      })
      .addCase(salesProjectsActions.getProjectsListAction.fulfilled, (state, action) => {        
        if (action.payload.data.data) {
          state.projectsListData= action.payload.data.data;
          state.projectsListError = "";
          state.activePagination = action.payload.data.pagination;
          state.projectDetailsError = "";
        } else {
          state.error = "Unable to fetch projects list";
          state.activePagination = {}
        }
      })
      .addCase(salesProjectsActions.getProjectsListAction.rejected, (state, action) => {
        state.projectsListData = [];
        state.projectsListError = action?.error?.message || "Unable to fetch projects list";
        state.activePagination = {}
      })

      // -------- cases of project details api data -------
      .addCase(salesProjectsActions.getProjectDetailsAction.pending, (state, action) => {
        state.projectDetailsError = "";
        // using loader value to check if project details need to be reset to null or not.
        if(action?.meta?.arg?.loader){
          state.projectDetails = null;
        }
      })
      .addCase(salesProjectsActions.getProjectDetailsAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.projectDetails= action?.payload?.data?.data;
          state.projectDetailsError = "";
        } else {
          state.projectDetailsError = "Unable to fetch project details";
        }
      })
      .addCase(salesProjectsActions.getProjectDetailsAction.rejected, (state, action:any) => {
        state.projectDetails = null;
        state.error = action?.payload?.data?.message || "Unable to fetch project details";
        state.projectDetailsError = action?.payload?.data?.message || "Unable to fetch project details";
      })

      // -------- cases of post project detailsroom api data -------
      .addCase(salesProjectsActions.postProjectDetailsRoomAction.pending, (state, action) => {
        state.error = "";
        state.projectDetailsRoomUpdate = null;
      })
      .addCase(salesProjectsActions.postProjectDetailsRoomAction.fulfilled, (state, action) => {        
        if (action.payload.data.data) {
          state.projectDetailsRoomUpdate= action.payload.data.data;
          state.error = "";
        } else {
          state.error = "Unable to fetch project details";
        }
      })
      .addCase(salesProjectsActions.postProjectDetailsRoomAction.rejected, (state, action) => {
        state.projectDetailsRoomUpdate = null;
        state.error = action?.error?.message || "Unable to fetch project details";
      })

      // -------- cases of project notes list api data -------
      .addCase(salesProjectsActions.getProjectDetailsNotesListingAction.pending, (state, action) => {
        state.projectDetailsNotesFilters = action.meta.arg? action.meta.arg: {};
        state.error = "";
        state.projectDetailsNotes = [];
        
        // reset state to avoid infinite api calls
        state.projectDetailsCreateNote = null;
      })
      .addCase(salesProjectsActions.getProjectDetailsNotesListingAction.fulfilled, (state, action) => {        
        if (action?.payload?.data?.status) {
          state.projectDetailsNotes= action?.payload?.data?.data;
          state.error = "";
        } else {
          state.error = "Unable to fetch project notes";
        }
      })
      .addCase(salesProjectsActions.getProjectDetailsNotesListingAction.rejected, (state, action:any) => {
        state.projectDetailsNotes = [];
        state.error = action?.payload?.data?.message || "Unable to fetch project notes";
      })

      // -------- cases of project create note api data -------
      .addCase(salesProjectsActions.postProjectDetailsNoteAction.pending, (state, action) => {
        state.error = "";
        state.projectDetailsCreateNote = null;
        // reset rest of the data points that can trigger notes list fetching request
        state.projectDetailsUpdateNote = null;
        state.projectDetailsDeleteNote = null;
      })
      .addCase(salesProjectsActions.postProjectDetailsNoteAction.fulfilled, (state, action) => {        
        if (action?.payload?.data?.status===200) {
          state.projectDetailsCreateNote= action?.payload?.data?.data;
          state.error = "";
        } else {
          state.error = "Unable to create new project note";
        }
      })
      .addCase(salesProjectsActions.postProjectDetailsNoteAction.rejected, (state, action:any) => {
        state.projectDetailsCreateNote = null;
        state.error = action?.payload?.data?.message || "Unable to create new project note";
      })

      // -------- cases of project update note api data -------
      .addCase(salesProjectsActions.updateProjectDetailsNoteAction.pending, (state, action) => {
        state.error = "";
        state.projectDetailsUpdateNote = null;
        // reset rest of the data points that can trigger notes list fetching request
        state.projectDetailsCreateNote = null;
        state.projectDetailsDeleteNote = null;
      })
      .addCase(salesProjectsActions.updateProjectDetailsNoteAction.fulfilled, (state, action) => {        
        if (action?.payload?.data?.status===200) {
          state.projectDetailsUpdateNote= action?.payload?.data?.message;
          state.error = "";
        } else {
          state.error = "Unable to update project note";
        }
      })
      .addCase(salesProjectsActions.updateProjectDetailsNoteAction.rejected, (state, action:any) => {
        state.projectDetailsUpdateNote = null;
        state.error = action?.payload?.data?.message || "Unable to update project note";
      })
      
      // -------- cases of project delete note api data -------
      .addCase(salesProjectsActions.deleteProjectDetailsNoteAction.pending, (state, action) => {
        state.error = "";
        state.projectDetailsDeleteNote = null;
        // reset rest of the data points that can trigger notes list fetching request
        state.projectDetailsCreateNote = null;
        state.projectDetailsUpdateNote = null;
      })
      .addCase(salesProjectsActions.deleteProjectDetailsNoteAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.projectDetailsDeleteNote= action?.payload?.data?.message;
          state.error = "";
        } else {
          state.error = "Unable to delete project note";
        }
      })
      .addCase(salesProjectsActions.deleteProjectDetailsNoteAction.rejected, (state, action:any) => {
        state.projectDetailsDeleteNote = null;
        state.error = action?.payload?.data?.message || "Unable to create new project note";
      })

      // -------- get root folders file -------
      .addCase(salesProjectsActions.getRootFoldersAction.pending, (state, action) => {
        state.error = "";
        state.projectFolders = null;
        state.docMediaFolders = [];
      })
      .addCase(salesProjectsActions.getRootFoldersAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.projectFolders= action?.payload?.data?.data;
          state.docMediaFolders= action?.payload?.data?.data;
          state.error = "";
        } else {
          state.error = "Failed to fetch folders.";
        }
      })
      .addCase(salesProjectsActions.getRootFoldersAction.rejected, (state, action:any) => {
        state.projectFolders = null;
        state.docMediaFolders = [];
        state.error = action?.payload?.data?.message || "Failed to fetch folders.";
      })

      // -------- get root folders file -------
      .addCase(salesProjectsActions.getChildFoldersAction.pending, (state, action) => {
        state.error = "";
        state.projectFolders = null;
        state.docMediaFolders = [];
      })
      .addCase(salesProjectsActions.getChildFoldersAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.projectFolders= action?.payload?.data?.data;
          state.docMediaFolders= action?.payload?.data?.data;
          state.error = "";
        } else {
          state.error = "Failed to fetch folders.";
        }
      })
      .addCase(salesProjectsActions.getChildFoldersAction.rejected, (state, action:any) => {
        state.projectFolders = null;
        state.docMediaFolders = [];
        state.error = action?.payload?.data?.message || "Failed to fetch folders.";
      })

      // -------- get uploaded document media api data -------
      .addCase(salesProjectsActions.getUploadedDocumentsMediaAction.pending, (state, action) => {
        state.error = "";
        state.uploadedDocMediaData = [];
      })
      .addCase(salesProjectsActions.getUploadedDocumentsMediaAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.uploadedDocMediaData= action?.payload?.data?.data;
          state.error = "";
        } else {
          state.error = "Unable to fetch data.";
        }
      })
      .addCase(salesProjectsActions.getUploadedDocumentsMediaAction.rejected, (state, action:any) => {
        state.uploadedDocMediaData = [];
        state.error = action?.payload?.data?.message || "Unable to fetch data";
      })

       // -------- upload documents.media api data -------
       .addCase(salesProjectsActions.uploadDocumentsMediaAction.pending, (state, action) => {
        state.uploadDocError = "";
        state.uploadedFileData = [];
      })
      .addCase(salesProjectsActions.uploadDocumentsMediaAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.uploadedFileData= action?.payload?.data?.message;
          state.uploadDocError = "";
        } else {
          state.uploadDocError = "Unable to upload file";
        }
      })
      .addCase(salesProjectsActions.uploadDocumentsMediaAction.rejected, (state, action:any) => {
        state.uploadedFileData = [];
        state.uploadDocError = action?.payload?.data?.message || "Unable to upload file";
      })

      // -------- update label -------
      .addCase(salesProjectsActions.updateLabelFileAction.pending, (state, action) => {
        state.error = "";
        state.updateLabel = [];
      })
      .addCase(salesProjectsActions.updateLabelFileAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.updateLabel= action?.payload?.data?.message;
          state.error = "";
        } else {
          state.error = "Unable to update label.";
        }
      })
      .addCase(salesProjectsActions.updateLabelFileAction.rejected, (state, action:any) => {
        state.updateLabel = [];
        state.error = action?.payload?.data?.message || "Unable to update label";
      })

      // -------- delete uploaded file -------
      .addCase(salesProjectsActions.deleteUploadedFileAction.pending, (state, action) => {
        state.error = "";
        state.deleteFile = [];
      })
      .addCase(salesProjectsActions.deleteUploadedFileAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.deleteFile= action?.payload?.data?.message;
          state.error = "";
        } else {
          state.error = "Unable to fetch file.";
        }
      })
      .addCase(salesProjectsActions.deleteUploadedFileAction.rejected, (state, action:any) => {
        state.deleteFile = [];
        state.error = action?.payload?.data?.message || "Unable to fetch file";
      })

      // -------- get document media uploaded file -------
      .addCase(salesProjectsActions.getDocumentMediaUploadedFilesAction.pending, (state, action) => {
        state.error = "";
        state.uploadedDocMediaFiles = [];
      })
      .addCase(salesProjectsActions.getDocumentMediaUploadedFilesAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.uploadedDocMediaFiles= action?.payload?.data?.data;
          state.error = "";
        } else {
          state.error = "Failed to fetch uploaded files.";
        }
      })
      .addCase(salesProjectsActions.getDocumentMediaUploadedFilesAction.rejected, (state, action:any) => {
        state.uploadedDocMediaFiles = [];
        state.error = action?.payload?.data?.message || "Failed to fetch uploaded files.";
      })

      // -------- get document media folders file -------
      .addCase(salesProjectsActions.getDocumentMediaFoldersAction.pending, (state, action) => {
        state.error = "";
        state.docMediaFolders = [];
      })
      .addCase(salesProjectsActions.getDocumentMediaFoldersAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.docMediaFolders= action?.payload?.data?.data;
          state.error = "";
        } else {
          state.error = "Failed to fetch folders.";
        }
      })
      .addCase(salesProjectsActions.getDocumentMediaFoldersAction.rejected, (state, action:any) => {
        state.docMediaFolders = [];
        state.error = action?.payload?.data?.message || "Failed to fetch folders.";
      })

      // -------- upload document media files -------
      .addCase(salesProjectsActions.uploadDocumentMediaFilessAction.pending, (state, action) => {
        state.error = "";
        state.docMediaFiles = [];
      })
      .addCase(salesProjectsActions.uploadDocumentMediaFilessAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.docMediaFiles= action?.payload?.data?.data;
          state.error = "";
        } else {
          state.error = "Failed to upload files.";
        }
      })
      .addCase(salesProjectsActions.uploadDocumentMediaFilessAction.rejected, (state, action:any) => {
        state.docMediaFiles = [];
        state.error = action?.payload?.data?.message || "Failed to upload files.";
      })

      // -------- update document media label -------
      .addCase(salesProjectsActions.updateDocumentMediaLabelAction.pending, (state, action) => {
        state.error = "";
        state.updateDocMediaLabels = [];
      })
      .addCase(salesProjectsActions.updateDocumentMediaLabelAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.updateDocMediaLabels= action?.payload?.data?.data;
          state.error = "";
        } else {
          state.error = "Failed to update labels.";
        }
      })
      .addCase(salesProjectsActions.updateDocumentMediaLabelAction.rejected, (state, action:any) => {
        state.deleteDocMedia = null;
        state.error = action?.payload?.data?.message || "Failed to update labels.";
      })

      // -------- delete document media file -------
      .addCase(salesProjectsActions.deleteDocumentMediaFileAction.pending, (state, action) => {
        state.error = "";
        state.deleteDocMedia = null;
      })
      .addCase(salesProjectsActions.deleteDocumentMediaFileAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.deleteDocMedia= action?.payload?.data?.success;
          state.error = "";
        } else {
          state.error = "Failed to delete file.";
        }
      })
      .addCase(salesProjectsActions.deleteDocumentMediaFileAction.rejected, (state, action:any) => {
        state.deleteDocMedia = null;
        state.error = action?.payload?.data?.message || "Failed to delete labels.";
      })

      // -------- download folder -------
      .addCase(salesProjectsActions.downloadFolderAction.pending, (state, action) => {
        state.error = "";
        state.downloadFolder = null;
      })
      .addCase(salesProjectsActions.downloadFolderAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.downloadFolder= action?.payload?.data;
          state.error = "";
        } else {
          state.error = "Failed to delete file.";
        }
      })
      .addCase(salesProjectsActions.downloadFolderAction.rejected, (state, action:any) => {
        state.downloadFolder = null;
        state.error = action?.payload?.data?.message || "Failed to delete labels.";
      })

      // -------- get project proposals -------
      .addCase(salesProjectsActions.getProjectProposalsAction.pending, (state, action) => {
        state.error = "";
        state.projectProposals = [];
      })
      .addCase(salesProjectsActions.getProjectProposalsAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.projectProposals= action?.payload?.data?.data;
          state.error = "";
        } else {
          state.error = "Failed to fetch project proposals.";
        }
      })
      .addCase(salesProjectsActions.getProjectProposalsAction.rejected, (state, action:any) => {
        state.projectProposals = [];
        state.error = action?.payload?.data?.message || "Failed to fetch project proposals.";
      })
  },
  
});

export const { cleanProjectDetails } = salesProjectsSlice.actions;

export default salesProjectsSlice.reducer;
