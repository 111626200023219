// Auth
export const LOGIN = "/login"; //login
export const FORGOT_PASSWORD = "/forgot-password"; //forgot password

// Users
export const USERS_LISTING = "/users/"; //User Listing
export const CREATE_USER = "/users/create/"; //Create User
export const UPDATE_USER = "users/update/:userId"; //Update User

// OFFICE
export const OFFICES_LISTING = "/offices"; // Office Listing
export const CREATE_OFFICE = "/offices/create/"; //Create Offices
export const UPDATE_OFFICE = "/offices/:officeId/"; //Update Offices

//Notifications
export const NOTIFICATIONS_LISTING = "/notifications"; // Office Listing
// Roles
export const CREATE_ROLE = "/users/roles/create"; //Create Role
export const EDIT_ROLE = "/users/role/:roleId"; //Create Role

// Projects
// export const PROJECTS = "/"; // Project2
export const CREATE_PROJECT = "/projects/create"; //Create Project

// Sales
// Sales Projects
export const SALES_PROJECTS = "/sales/projects"; // Sales Project
export const SALES_PROJECT_DETAILS = "/sales/projects/details/:projectId"; // Sales Project

// Sales Proposals
export const SALES_PROPOSALS = "/sales/proposals"; // Proposals
export const SALES_PROPOSAL_DETAILS = "/sales/proposals/details/:proposalId"; // Sales Proposal Details

// Project Management
// Dashboard
export const PM_DASHBOARD = "/projectManagement/dashboard"; // Dashboard
export const PM_PROJECTS = "/projectManagement/projects"; // PM Projects
export const PM_PROJECT_DETAILS = "/projectManagement/projects/details/:projectId"; // PM Projects
export const PM_PROPOSAL_DETAILS = "/projectManagement/proposals/details/:proposalId"; // PM Proposals

// Design Management
export const DM_DASHBOARD = "/designManagement/dashboard"; // Dashboard
export const DM_PROJECTS = "/designManagement/projects"; // DM Projects
export const DM_PROJECT_DETAILS = "/designManagement/projects/details/:projectId"; // DM Projects
export const DM_PROPOSAL_DETAILS = "/designManagement/proposals/details/:proposalId"; // DM Proposals

// Mobile View
export const PM_PROJECT_DETAILS_MOBILE = "/mobile/projectManagement/projects/details/:projectId"; // PM Projects mobile

// Finance
// Dashboard
export const FM_DASHBOARD = "/financeManagement/dashboard"; // Dashboard
export const FM_DASHBOARD_ALL = "/financeManagement/dashboard/all";
export const FM_DASHBOARD_INVOICES = "/financeManagement/dashboard/invoices";

export const FM_DASHBOARD_CASH_FLOW = "/financeManagement/dashboard/project-cash-flow";
export const FM_DASHBOARD_PROJECT_CASH_FLOW = "/financeManagement/dashboard/project-cash-flow/details/:projectId";

export const FM_DASHBOARD_PROFIT_MARGINS = "/financeManagement/dashboard/profit-margins";
export const FM_DASHBOARD_BUDGET_ACTUAL_SPEND = "/financeManagement/dashboard/budget-actual-spend";
export const FM_DASHBOARD_EXPENSES = "/financeManagement/dashboard/expenses";
export const FM_DASHBOARD_VENDORS_PAYMENTS = "/financeManagement/dashboard/vendors-payments";
export const FM_DASHBOARD_SUBCONTRACTOR_PAYMENTS = "/financeManagement/dashboard/subcontractor-payments";

export const FM_PROJECTS = "/financeManagement/projects"; // fm Projects
export const FM_PROJECT_DETAILS = "/financeManagement/projects/details/:projectId"; // fm Projects
export const FM_PROPOSAL_DETAILS = "/financeManagement/proposals/details/:proposalId"; // fm Proposals

// Design Management
export const DESIGN_DASHBOARD = "/design/dashboard"; // Dashboard
export const DESIGN_PROJECTS = "/design/projects"; // PM Projects
export const DESIGN_PROJECT_DETAILS = "/design/projects/details/:projectId"; // PM Projects

// under maintenance
export const UNDER_MAINTENANCE = "/under-maintenance"; 

// privacy
export const PRIVACY_POLACY ="/privacy-policy"  

// 404
export const PAGE_NOT_FOUNT ="/404"