export const PER_PAGE = 10;
export const DATE_FORMAT = "MM/DD/YYYY";
export const jobTypes = [
    "All",
    "Kitchen",
    "Bathroom",
    "Bed Room",
    "Living Room"
];

export const seamlessLoginUrls = [
  // save proposal items
  `${process.env.REACT_APP_BASE_URL}/sales/proposal/save-items`,
  // save proposal item detail
  `${process.env.REACT_APP_BASE_URL}/sales/proposal/<UUID>/group/<UUID>/items/<UUID>`,
  // save proposal group detail
  `${process.env.REACT_APP_BASE_URL}/sales/proposal/<UUID>/group/<UUID>`,
  // save project detail
  `${process.env.REACT_APP_BASE_URL}/projects/<UUID>`,
  // save proposal payment schedule detail
  `${process.env.REACT_APP_BASE_URL}/sales/proposal/save-payment-schedule`,
  // save change order detail
  `${process.env.REACT_APP_BASE_URL}/pm/project/change-order/<UUID>/items`,
  // save project overview tasks
  `${process.env.REACT_APP_BASE_URL}/pm/<UUID>/project/<UUID>/task`
];

export const EliMail = "eli@hellochapter.com";

export const InvoicestableauUrl = `https://us-east-1.online.tableau.com/t/chapterproduction/views/Invoices/DashboardInvoices/8d43349f-e5bb-4b38-840f-d026e758bd5d/ce6a46b8-ebd7-4ef8-a5dc-980fdd0b9531`;
export const ExpensestableauUrl = `https://us-east-1.online.tableau.com/t/chapterproduction/views/Expenses/ExpensesDashboard`;
export const ProjectCashFlowtableauUrl = `https://us-east-1.online.tableau.com/t/chapterproduction/views/Cashflow/CashflowDashboard`;
export const ProfitMarginstableauUrl = `https://us-east-1.online.tableau.com/t/chapterproduction/views/ProfitMargin/ProfitMarginDashboard`;
export const BudgevsActualSpendtableauUrl = `https://us-east-1.online.tableau.com/t/chapterproduction/views/Budgetvs_ActualExpensesChart/Budgetvs_ActualExpensesDashboard`;
export const VendorsPaymentstableauUrl = `https://us-east-1.online.tableau.com/t/chapterproduction/views/VendorsPayments/VendorsPaymentsDashboard`;
export const SubcontractorsPaymentstableauUrl = `https://us-east-1.online.tableau.com/t/chapterproduction/views/SubcontractorsPayments/SubcontractorsPaymentsDashboard`;

export const allowedFileTypes = ".pdf,.docx,.xlsx,.csv,.txt,.jpg,.jpeg,.png,.heic,.tiff,.obj,.fbx,.gltf,.glb,.usdz,.stl,.ply,.dae,.dwg,.dxf,.skp,.rvt,.ifc,.svg,.mp4,.mov,.avi,.mkv,.webm,.m4v";

export const allowedDocTypes = ".pdf,.docx,.xlsx,.csv,.txt";
export const allowedImgTypes = ".jpg,.jpeg,.png,.heic,.tiff";
export const allowed3DTypes = ".obj,.fbx,.gltf,.glb,.usdz,.stl,.ply,.dae,.dwg,.dxf,.skp";
export const allowedVecType = ".rvt,.ifc";
export const allowedBimType = ".svg";
export const allowedVideoTypes = ".mp4,.mov,.avi,.mkv,.webm,.m4v";